// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
// auth
import { useAuthContext } from 'src/auth/hooks';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
    title?: string;
};

export default function AuthClassicLayout({ children, title }: Props) {
    const { method } = useAuthContext();
    const mdUp = useResponsive('up', 'md');

    const renderContent = (
        <Stack
            sx={{
                width: 1,
                mx: 'auto',
                maxWidth: 480,
                px: { xs: 2, md: 8 },
                pt: { xs: 15, md: 20 },
                pb: { xs: 15, md: 0 },
                textAlign: 'center', // Allinea al centro
            }}
        >
            {title && (
                <Typography variant="h4" sx={{ mb: 3 }}>
                    {title}
                </Typography>
            )}

            {children}

            {/* Forgot password link */}
            <Box sx={{ mt: 2 }}>
                <Link
                    component={RouterLink}
                    href="/forgot-password"
                    variant="body2"
                    sx={{ display: 'block', mt: 2, textAlign: 'center' }} // Centrato
                >
                    Forgot password?
                </Link>
            </Box>
        </Stack>
    );

    return (
        <Stack
            component="main"
            direction="row"
            sx={{
                minHeight: '100vh',
                justifyContent: 'center', // Centra verticalmente il contenuto
                alignItems: 'center' // Centra orizzontalmente il contenuto
            }}
        >
            {renderContent}
        </Stack>
    );
}

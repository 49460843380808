import { useEffect, useMemo, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

const icon = (name: string) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
    job: icon('ic_job'),
    blog: icon('ic_blog'),
    chat: icon('ic_chat'),
    mail: icon('ic_mail'),
    user: icon('ic_user'),
    file: icon('ic_file'),
    lock: icon('ic_lock'),
    tour: icon('ic_tour'),
    order: icon('ic_order'),
    label: icon('ic_label'),
    blank: icon('ic_blank'),
    kanban: icon('ic_kanban'),
    folder: icon('ic_folder'),
    banking: icon('ic_banking'),
    booking: icon('ic_booking'),
    invoice: icon('ic_invoice'),
    product: icon('ic_product'),
    calendar: icon('ic_calendar'),
    disabled: icon('ic_disabled'),
    external: icon('ic_external'),
    menuItem: icon('ic_menu_item'),
    ecommerce: icon('ic_ecommerce'),
    analytics: icon('ic_analytics'),
    dashboard: icon('ic_dashboard'),
    jackpot: icon('ic_jackpot')
};

// ----------------------------------------------------------------------

export function useNavData() {
    const { user } = useAuthContext();

    const ShoperList = useMemo(
        () => [
            {
                title: 'Users',
                path: paths.user.index,
                icon: ICONS.user
            },
            {
                title: 'Message',
                path: paths.message.index,
                icon: ICONS.chat
            },
            {
                title: 'Profile',
                path: paths.profile.index,
                icon: ICONS.job
            },
            {
                title: 'ID Validation',
                path: paths.idcardvalidation.index,
                icon: ICONS.user
            }
        ],
        []
    );

    const defaultList = useMemo(
        () => [
            {
                title: 'Cashiers',
                path: paths.shop.index,
                icon: ICONS.order,
                children: [
                    { title: 'List', path: paths.shop.list },
                    { title: 'Create', path: paths.shop.create }
                ]
            },
            {
                title: 'Users',
                path: paths.user.index,
                icon: ICONS.user
            },
            {
                title: 'Message',
                path: paths.message.index,
                icon: ICONS.chat
            },
            {
                title: 'Profile',
                path: paths.profile.index,
                icon: ICONS.job
            },
            {
                title: 'ID Validation',
                path: paths.idcardvalidation.index,
                icon: ICONS.user
            }
        ],
        []
    );

    const superAdminList = useMemo(
        () => [
            {
                title: 'Provider Management',
                path: paths.games.provider,
                icon: ICONS.invoice
            },
            {
                title: 'Game Management',
                path: paths.games.game,
                icon: ICONS.invoice
            },
            {
                title: 'Network Logs',
                path: paths.dashboard.netlogs,
                icon: ICONS.lock
            },
            {
                title: 'Messages',
                path: paths.message.index,
                icon: ICONS.chat
            }
        ],
        []
    );

    const [realNavBar, setRealNavBar] = useState(defaultList);

    useEffect(() => {
        if (user?.roleId === 'shop') setRealNavBar(ShoperList);
        if (user?.roleId === 'super_admin') setRealNavBar(superAdminList);
    }, [user, ShoperList, superAdminList]);

    const data = useMemo(
        () => [
            {
                subheader: 'OVERVIEW',
                items: [
                    { title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
                    {
                        title: 'Game Reports',
                        path: paths.dashboard.gamestatics,
                        icon: ICONS.analytics
                    },
                    {
                        title: 'Sport Reports',
                        path: paths.dashboard.sportsstatics,
                        icon: ICONS.external
                    },
                    {
                        title: 'Deposits',
                        path: paths.payment.deposit,
                        icon: ICONS.blog
                    },
                    {
                        title: 'Withdrawals',
                        path: paths.payment.withdraw,
                        icon: ICONS.blog
                    }
                ]
            },
            {
                subheader: 'ACCOUNTS',
                items: [
                    {
                        title: 'Managers List',
                        path: paths.shop.list,
                        icon: ICONS.order
                    },
                    {
                        title: 'Create Manager',
                        path: paths.shop.create,
                        icon: ICONS.order
                    },
                    {
                        title: 'Players',
                        path: paths.user.index,
                        icon: ICONS.user
                    },
                    {
                        title: 'ID Validation',
                        path: paths.idcardvalidation.index,
                        icon: ICONS.user
                    },
                    {
                        title: 'My Account',
                        path: paths.profile.index,
                        icon: ICONS.job
                    }
                ]
            },
            {
                subheader: 'MANAGEMENT',
                items: realNavBar
            }
        ],
        [realNavBar]
    );

    return data;
}
